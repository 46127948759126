import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import Subtitle from "../components/Subtitle";
import { Card, CardActionArea, Grid } from "@mui/material";
import Container from "../components/Container";
import { Navigate } from "react-router";
import {
  setOriginalImagePath,
  setModifiedImagePath,
  setImageName,
} from "../../business-logic/redux/reducers/imageSlice";
import { useAppDispatch } from "../../business-logic/redux/hooks";
import { imageService } from "../../business-logic/services/ImageServices";
import Error, { ErrorTypes } from "../../business-logic/services/Error";

interface Props {
  processed?: boolean;
  errorHandler: (
    error: Error,
    setRedirect: Dispatch<SetStateAction<boolean>>
  ) => void;
}

interface Image {
  name: string;
  path: string;
}

export default function Previous({ errorHandler, processed = false }: Props) {
  const [images, setImages] = useState<Image[]>([]);
  const [redirect, setRedirect] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (processed) {
      imageService
        .getAllProcessedImages()
        .then((res) => {
          setImages(
            res.map((image) => {
              return {
                name: image.uploaded_image_name,
                path: image.processed_image_path,
              };
            })
          );
        })
        .catch((err) => {
          errorHandler(new Error(ErrorTypes.FailedToFetch, err), setRedirect);
        });
    } else {
      imageService
        .getAllImages()
        .then((res) => {
          setImages(
            res.map((image) => {
              return { name: image.name, path: image.image_path };
            })
          );
        })
        .catch((err) => {
          errorHandler(new Error(ErrorTypes.FailedToFetch, err), setRedirect);
        });
    }
  });

  const onClickImage = (image: Image) => {
    dispatch(setImageName(image.name));
    processed ? clickOnModifiedImage(image): clickOnOriginalImage(image);
    setRedirect(true);
  };

  const clickOnOriginalImage = (image: Image) => {
    dispatch(setOriginalImagePath(image.path));
    dispatch(setModifiedImagePath(""));
  }

  const clickOnModifiedImage = (image: Image) => {
    dispatch(setModifiedImagePath(image.path));
    imageService.getImage(image.name).then((image)=> {
      dispatch(setOriginalImagePath(image.image_path));
    })
  }

  if (redirect) {
    return <Navigate to="/" />;
  }

  return (
    <Container page>
      <Subtitle
        variant="h3"
        title={processed ? "previous processed images" : "previous images"}
      />
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        {images.length ? (
          images.map((image) => (
            <Grid
              // sx={{ height: "200px" }}
              key={image.name + Math.random()}
              item
              rowSpacing={4}
              columnSpacing={4}
              gap={4}
              xs={8}
              md={8}
              lg={5}
              xl={5}
            >
              <Card sx={{ minHeight: "200px", padding: "5px" }}>
                <CardActionArea onClick={() => onClickImage(image)}>
                  <img
                    style={{ maxWidth: "100%", borderRadius: "0.3em", flexGrow: 1 }}
                    alt={`previousImage: ${image}`}
                    src={image.path}
                  />
                </CardActionArea>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item style={{marginTop: '100px'}}>
            <Subtitle title="no images found" />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
