import React from "react";
import { Typography } from "@mui/material";
import {useTranslation} from 'react-i18next';

export default function Subtitle({
  variant = "h4",
  title,
}: {
  variant?:
    | "h4"
    | "h1"
    | "h2"
    | "h3"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "button"
    | "overline"
    | "inherit";
  title: string;
}) {
  const {t} = useTranslation();
  return (
    <Typography color={'primary'} gutterBottom noWrap variant={variant}>
      {t(title)}
    </Typography>
  );
}
