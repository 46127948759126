import React, { Dispatch, SetStateAction, useState, useMemo } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import "./App.css";
import Container from "./components/Container";
import Menu from "./components/Menu";
import Background from "./components/Background";
import {
  Backdrop,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { purple } from "@mui/material/colors";
import Error, { ErrorInfo } from "../business-logic/services/Error";
import ErrorDialog from "./components/Error";
import Previous from "./pages/Previous";
import Subtitle from "./components/Subtitle";

export enum LIGHT_DARK {
  LIGHT = "light",
  DARK = "dark",
}

const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const About = React.lazy(() => import("./pages/About"));
const ImageProcessing = React.lazy(() => import("./pages/ImageProcessing"));

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [errorCode, setErrorCode] = useState(-1);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);

  const errorHandler = (
    error: Error,
    setRedirect?: Dispatch<SetStateAction<boolean>>
  ) => {
    try {
      if (error.getHttpStatus() === 401) {
        setRedirect && setRedirect(true);
        return;
      }
      error.resolve().then((errorInfo: ErrorInfo) => {
        setErrorCode(errorInfo.errorCode);
        setErrorMessage(errorInfo.errorText);
        setError(true);
      });
    } catch (error) {
      setErrorCode(-1);
      setErrorMessage("Unknown error");
      setError(true);
    }
  };

  return (
    <Container>
      <Menu />
      <Background />
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route index element={<Navigate replace to="/image-processing" />} />
          <Route path="about" element={<About />} />
          <Route
            path="previous"
            element={<Previous errorHandler={errorHandler} />}
          />
          <Route
            path="previous/processed"
            element={<Previous errorHandler={errorHandler} processed />}
          />
        </Route>
        <Route path="/image-processing">
          <Route
            index
            element={
              <ImageProcessing
                errorHandler={errorHandler}
                setIsLoading={setIsLoading}
              />
            }
          ></Route>
        </Route>
        <Route path="/dashboard" element={<Navigate replace to="/" />} />
        <Route path="/*" element={<Navigate replace to="/" />} />
      </Routes>
      {error && (
        <ErrorDialog
          statusCode={errorCode}
          errorMessage={errorMessage}
          resetError={() => setError(false)}
          redirectPath="/image-processing"
          redirectName="Home"
        />
      )}
      <Backdrop
        sx={{
          display: "flex",
          flexDirection: "column",
          color: "primary.main",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
      >
        <CircularProgress size={80} color="inherit" />
        <br />
        <Subtitle title="...is loading" />
      </Backdrop>
    </Container>
  );
}

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
});

function ToggleColorMode() {
  const [mode, setMode] = useState<LIGHT_DARK>(LIGHT_DARK.LIGHT);
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) =>
          prevMode === LIGHT_DARK.LIGHT ? LIGHT_DARK.DARK : LIGHT_DARK.LIGHT
        );
      },
    }),
    []
  );

  const theme = useMemo(() => {
    let t = createTheme({
      palette: {
        mode,
        ...(mode === "light"
          ? {
              primary: {
                main: purple[500],
                light: purple[200],
              },
              secondary: {
                main: "#11cb5f",
              },
            }
          : {
              primary: {
                main: purple[200],
                light: purple[200],
              },
              secondary: {
                main: "#3aef86",
              },
            }),
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
        },
      },
    });

    t = createTheme(t, {
      components: {
        section: {
          style: {
            backgroundColor: "blue",
          },
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              borderBottom: "3px solid",
              borderImage: `linear-gradient(180deg, ${t.palette.primary.main} 98%, ${t.palette.secondary.main}) 1`,
              backgroundColor: t.palette.secondary.main,
            },
          },
        },
        MuiTypography: {
          variants: [
            {
              props: { variant: "h1" },
              style: {
                color: t.palette.primary.main,
              },
            },
            {
              props: { variant: "h2" },
              style: {
                color: t.palette.primary.main,
              },
            },
          ],
        },
      },
    });
    return t;
  }, [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
export default ToggleColorMode;
