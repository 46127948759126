import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./presentation/App";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from "./presentation/translations/index";
import { Provider } from "react-redux";
import { store } from "./business-logic/redux/store";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translations.translationEn,
    },
    de: {
      translation: translations.translationDe,
    },
  },
  lng: "de",
  fallbackLng: "de",

  interpolation: {
    escapeValue: false,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// add <React.StrictMode> around all on production
root.render(
  <>
    {/* <React.StrictMode> */}
    {/* <ThemeProvider theme={theme}> */}
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      {/* </ThemeProvider> */}
      {/* </React.StrictMode> */}
    </Provider>
  </>
);
