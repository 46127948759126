export class ErrorInfo {
  constructor(public errorText: string, public errorCode: number) {}
}

export enum ErrorTypes {
  Backend, //eslint-disable-line
  FailedToFetch, //eslint-disable-line
  InvalidArgument, //eslint-disable-line
  PropertyNullOrUndefined, //eslint-disable-line
}

export default class Error {
  constructor(
    private errorType: ErrorTypes,
    private errorResponse?: Response
  ) {}

  async resolve(): Promise<ErrorInfo> {
    switch (this.errorType) {
      case ErrorTypes.Backend:
        try {
          const responseObj = await this.errorResponse!.json();
          if (typeof responseObj.status === "string") {
            return new ErrorInfo(
              responseObj.status,
              this.errorResponse!.status
            );
          } else {
            return new ErrorInfo(
              responseObj.status.message,
              this.errorResponse!.status
            );
          }
        } catch (error) {
          return new ErrorInfo("Unable to read error message", 2);
        }
      case ErrorTypes.FailedToFetch:
        return new Promise((res) => res(new ErrorInfo("Failed to fetch", 1)));
      case ErrorTypes.InvalidArgument:
        return new Promise((res) =>
          res(new ErrorInfo("Invalid Arguments on function call", 3))
        );
      case ErrorTypes.PropertyNullOrUndefined:
        return new Promise((res) =>
          res(new ErrorInfo("Property can not be undefined or null", 4))
        );
      default:
        return new Promise((res) => res(new ErrorInfo("Unknown", 50)));
    }
  }

  public getHttpStatus(): number {
    return  -1;//this.errorResponse?.status || -1;
  }
}
