import Error, { ErrorTypes } from '../business-logic/services/Error';

export class DataResource {
    constructor(private backendURL: string) {
        this.backendURL = backendURL;
    }

    getJson(endpoint: string) : Promise<any> {
        return fetch(`${this.backendURL}${endpoint}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                Accept: 'application/json'
            }
        }).then((e) => {
            return this.checkStatus(e);
        }).catch(this.handleError);
    }

    postJson(endpoint: string, body: Record<string, unknown>) : Promise<Response> {
        return fetch(`${this.backendURL}${endpoint}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            },
            body: JSON.stringify(body),
        }).then((e) => {
            return this.checkStatus(e);
        }).catch(this.handleError);
    }

    patchJson(endpoint: string, params: Record<string, unknown>) : Promise<any> {
        return fetch(`${this.backendURL}${endpoint}`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            },
            body: JSON.stringify(params),
        }).then((e) => {
            return this.checkStatus(e);
        }).catch(this.handleError);
    }

    deleteJson(endpoint: string) : Promise<Response> {
        return fetch(`${this.backendURL}${endpoint}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then((e) => {
            return this.checkStatus(e);
        }).catch(this.handleError);
    }

    checkStatus(response: Response) : Response {
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            throw new Error(ErrorTypes.Backend, response);
        }
    }

    handleError(error: Response) : Response {
        if (error.toString()==='TypeError: Failed to fetch') {
            throw new Error(ErrorTypes.FailedToFetch);
        }
        throw error;
    }
}

export const dataResource = new DataResource(window.location.protocol + '//' + window.location.hostname + '/api');
