import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SecurityState {
  captchaToken: string;
}

const initialState: SecurityState = {
  captchaToken: "",
};

export const securitySlice = createSlice({
  name: "security",
  initialState,
  reducers: {
    setCaptchaToken: (state, action: PayloadAction<string>) => {
      state.captchaToken = action.payload;
    },
  },
});

export const { setCaptchaToken } = securitySlice.actions;

export default securitySlice.reducer;
