import React from "react";

export default function Container({
  children,
  page = false,
  style = {},
}: {
  children: React.ReactNode;
  page?: boolean;
  style?: React.CSSProperties;
}) {
  return (
    <section
      className={`${page ? "container page" : "container"}`}
      style={{ ...style }}
    >
      {children}
    </section>
  );
}
