import React, { useEffect, useContext, useState } from "react";
import { Button, IconButton, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import { ColorModeContext } from "../App";
import logo from "../../assets/ost-logo-de.svg";
import { useTranslation } from "react-i18next";

const PAGES: { path: string; name: string }[] = [
  // { path: "about", name: "About" },
  { path: "", name: "Home" },
  { path: "previous", name: "previous" },
  { path: "previous/processed", name: "previous processed" },
];

const NAVBAR_ID = "navbar";

const getStyle = (mode: "light" | "dark" = "light"): Object => ({
  padding: "5px",
  height: "60px",
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  display: "flex",
  alignItems: "center",
  transition: "top 0.3s",
  backgroundColor: mode === "light" ? "white" : "lightgrey",
  zIndex: 100,
  boxShadow: "0px 0px 31px 5px lightgrey",
});

enum Language {
  EN = "EN",
  DE = "DE",
}

export default function Menu() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState<Language>(Language.DE);
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  let prevScrollpos = window.pageYOffset;

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const navBar = document.getElementById(NAVBAR_ID);
    if (navBar) {
      const currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        navBar.style.top = "0";
      } else {
        navBar.style.top = "-100px";
      }
      prevScrollpos = currentScrollPos;
    }
  };

  const toggleLanguage = () => {
    switch (language) {
      case Language.DE:
        setLanguage(Language.EN);
        break;
      case Language.EN:
        setLanguage(Language.DE);
        break;
      default:
        setLanguage(Language.DE);
    }
    i18n.changeLanguage(language.toLocaleLowerCase());
  };

  return (
    <div id={NAVBAR_ID} style={getStyle(theme.palette.mode)}>
      <Link className="nav-link" to={"/"}>
        <img
          src={logo}
          alt="OST Logo"
          style={{
            height: "100%",
            // filter: `invert(${theme.palette.mode === "dark" ? 0.5 : 0})`,
          }}
          onClick={() => {}}
        />
      </Link>
      {/* <div style={{ flexGrow: 1 }}></div>
      <Button onClick={toggleLanguage}>{language}</Button> */}
      <div style={{ flexGrow: 1 }}></div>
      {PAGES.sort((a, b) =>
        a.name.localeCompare(b.name, "en", { sensitivity: "base" })
      ).map((page) => (
        <Button key={page.path} style={{ height: "100%" }}>
          <Link className="nav-link" to={"/" + page.path}>
            {t(page.name)}
          </Link>
        </Button>
      ))}
      <IconButton
        sx={{ ml: 1 }}
        onClick={colorMode.toggleColorMode}
        color="inherit"
      >
        <FontAwesomeIcon
          icon={theme.palette.mode === "dark" ? faMoon : faSun}
          style={{ filter: `invert(${theme.palette.mode === "dark" ? 1 : 0})` }}
        />
      </IconButton>
    </div>
  );
}
