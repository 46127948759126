import { configureStore } from '@reduxjs/toolkit'
import imageReducer from './reducers/imageSlice';
import securityReducer from './reducers/securitySlice';


export const store = configureStore({
  reducer: {
    image: imageReducer,
    security: securityReducer
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch