import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
  Backdrop,
} from "@mui/material";
import { Navigate } from "react-router";

interface Props {
  statusCode: number | null;
  errorMessage: string | null;
  resetError: () => void;
  redirectPath?: string;
  redirectName?: string;
}

export default function Error({
  statusCode,
  errorMessage,
  resetError,
  redirectPath,
  redirectName,
}: Props): React.ReactElement {
  const [open, setOpen] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    resetError();
  };

  if (redirect) {
    handleClose();
    return <Navigate to={redirectPath || "/"} />;
  }

  return (
    <Backdrop open={open} onClick={handleClose} style={{ zIndex: 10 }}>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2">
            Error
          </Typography>
          <Typography variant="body2" component="p">
            Statuscode: {statusCode}
            <br />
            Error Message:{" "}
            {errorMessage ? errorMessage : "No description available"}
          </Typography>
        </CardContent>
        <CardActions>
          {redirectPath && (
            <Button
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setRedirect(true);
              }}
            >
              {redirectName || "Seite neu laden"}
            </Button>
          )}
          <Button size="small">Ok</Button>
        </CardActions>
      </Card>
    </Backdrop>
  );
}
