import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ImageState {
  originalImagePath: string;
  modifiedImagePath: string;
  imageName: string;
  base64Image: string;
  mimeType: string;
}

const initialState: ImageState = {
  originalImagePath: "",
  modifiedImagePath: "",
  imageName: "",
  base64Image: "",
  mimeType: "",
};

export const imageSlice = createSlice({
  name: "image",
  initialState,
  reducers: {
    setImageName: (state, action: PayloadAction<string>) => {
      state.imageName = action.payload;
    },
    setOriginalImagePath: (state, action: PayloadAction<string>) => {
      state.originalImagePath = action.payload;
    },
    setModifiedImagePath: (state, action: PayloadAction<string>) => {
      state.modifiedImagePath = action.payload;
    },
    setBase64Image: (state, action: PayloadAction<string>) => {
      state.base64Image = action.payload;
    },
    setMimeType: (state, action: PayloadAction<string>) => {
      state.mimeType = action.payload;
    },
  },
});

export const {
  setOriginalImagePath,
  setModifiedImagePath,
  setImageName,
  setBase64Image,
  setMimeType
} = imageSlice.actions;

export default imageSlice.reducer;
