import React from "react";

export default function Background() {

  return (
    <div style={{position: "fixed", top: 0, right: 0, left: 0, bottom: 0, background: 'radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(196,196,196,1) 100%)', zIndex: -100}}>
      
    </div>
  );
}
