import { DataResource, dataResource } from "../../data/dataResource";
import { FilterOption, ModelOptions } from "./interfaces/RequestInterfaces";
import {
  DocumentResponse,
  FilterResponse,
  ImageResponse,
  ProcessedImageResponse,
} from "./interfaces/ResponseInterfaces";

export class ImageService {
  constructor(private dataResource: DataResource) {}

  async getAllImages(): Promise<ImageResponse[]> {
    const response = await this.dataResource.getJson(`/uploads/?format=json`);
    return response.json();
  }

  async getImage(imageName: string): Promise<ImageResponse> {
    const response = await this.dataResource.getJson(
      `/uploads/${imageName}/?format=json`
    );
    return response.json();
  }

  async uploadImage(
    base64Image: string,
    mimeType: string,
    token: string,
    imageName: string = "noName"
  ): Promise<void> {
    const response = await this.dataResource.postJson(`/uploads/`, {
      name: imageName,
      image: { mime: mimeType, data: base64Image },
      // TODO: if captcha works send with token
      // token
    });
    return response.json();
  }

  async getAllFilters(): Promise<FilterResponse> {
    const response = await this.dataResource.getJson(`/filter/?format=json`);
    return response.json();
  }

  async getAllProcessedImages(): Promise<ProcessedImageResponse[]> {
    const response = await this.dataResource.getJson(`/processed/?format=json`);
    return response.json();
  }

  async getProcessedImage(imageName: string): Promise<ProcessedImageResponse> {
    const response = await this.dataResource.getJson(
      `/processed/${imageName}/?format=json`
    );
    return response.json();
  }

  async applyFiltersToImage(
    imageName: string,
    filters: FilterOption[]
  ): Promise<void> {
    const response = await this.dataResource.postJson(
      `/apply_filter/${imageName}/`,
      { options: filters }
    );
    return response.json();
  }

  async createAndGet3DModelOfImage(
    imageName: string,
    modelOptions: ModelOptions
  ): Promise<DocumentResponse> {
    const response = await this.dataResource.postJson(
      `3d_model/${imageName}/`,
      {
        displacement_strength: modelOptions.displacement_strength,
        layer_height: modelOptions.layer_height,
        plate_depth: modelOptions.plate_depth,
        scale: modelOptions.scale,
      }
    );
    return response.json();
  }
}

export const imageService = new ImageService(dataResource);
